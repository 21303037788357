import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/codebuild/output/src430903205/src/dashbot-home-gatsby/src/layouts/blog/layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Customer service is one of the biggest areas of growth we see in chatbots.`}</p>
    <p>{`Enterprises want to be able to provide 24/7 support, but the costs to do so with live agents can be cost prohibitive.`}</p>
    <p>{`Consumers are becoming more accustomed to interacting with chatbots for customer support.`}</p>
    <p>{`There are still challenges ahead to provide effective customer service through chatbots.`}</p>
    <p>{`We recently conducted two surveys to dive deeper into customer service chatbots – one with enterprises and the other with end-user consumers. Below are the results.`}</p>
    <h2>{`Enterprise customer service chatbots are on the rise`}</h2>
    <p><img parentName="p" {...{
        "src": "https://i.dashbot.io/wp-home/uploads/2019/04/22231307/enterprise-bots.png",
        "alt": null
      }}></img></p>
    <p>{`Our enterprise survey focused on companies that either offer live-agent chat, an automated chatbot, or both, on their website. Nearly half of the respondents currently only have live-agent chat (48.5%) versus 19% who only have a chatbot, and 32% who have both.`}</p>
    <p>{`However, nearly 70% of the enterprises that currently do not have a chatbot, are looking to build one in the next year. In fact, 43% are looking to do so in the next three months, with an additional 17% planning to do so in the next 6 months.`}</p>
    <p>{`Based on these results, customer service chatbots are on the rise.`}</p>
    <h2>{`Why build a chatbot for customer service?`}</h2>
    <p>{`We asked enterprises why they want to build a chatbot.`}</p>
    <p><img parentName="p" {...{
        "src": "https://i.dashbot.io/wp-home/uploads/2019/04/22231315/whybot.png",
        "alt": null
      }}></img></p>
    <p>{`Enterprises are not just building chatbots to increase availability and reduce costs, they are doing it to provide a better customer service experience. While providing 24/7 support was the top answer at 76%, improving customer service and providing a better user experience were right up there at 59% and 57% respectively. Similarly, providing a consistent user experience was fairly close at 52%.`}</p>
    <p>{`It appears enterprises see customer service chatbots as not just a cost savings, but a better overall experience. In fact, reducing costs and reducing the number of live agents were towards the bottom at 44% and 40% respectively.`}</p>
    <h2>{`Common use-cases`}</h2>
    <p>{`We asked enterprises what are the more common types of use cases their live-agents or chatbots handle.`}</p>
    <p><img parentName="p" {...{
        "src": "https://i.dashbot.io/wp-home/uploads/2019/04/22231314/enterprise-usecases.png",
        "alt": null
      }}></img></p>
    <p>{`General questions (FAQs) and product help questions tended to be the top use cases for enterprises offering either live-agent chat, chatbots, or both.`}</p>
    <p>{`Where use-cases start to differ a bit amongst enterprises providing live agent and chatbots, are in post sales support and handling complaints. Post sales support tends to be more common for enterprises with live-agents rather than chatbots. Similarly handling complaints tends to be more common for live-agents than chatbots.`}</p>
    <h2>{`What is important in a chatbot`}</h2>
    <p>{`We asked enterprises how important reducing escalation, understanding the user, and providing user satisfaction are for their chatbot initiatives.`}</p>
    <p><img parentName="p" {...{
        "src": "https://i.dashbot.io/wp-home/uploads/2019/04/22231308/enterprise-importance.png",
        "alt": null
      }}></img></p>
    <p>{`User satisfaction was the most important, followed closely by understanding the user. While reducing escalation is also important, it is slightly less than the first two, which coincides a bit with the earlier results that only 40% of enterprises are offering chatbots to reduce live agents.`}</p>
    <p>{`Developing for conversational interfaces can be challenging. It is difficult to know all the possible things someone might write to a chatbot, or all the different ways they might write them. Being able to better understand the user and optimizing the Natural Language Processing (NLP) to do so are quite important. Similarly, it is not just about understanding what the user said, but providing an appropriate response that satisfies the user.`}</p>
    <h2>{`What about consumers?`}</h2>
    <p>{`As mentioned above, we conducted a second survey of consumers who have interacted with a live agent, chatbot, or both, to better understand their experiences and thoughts.`}</p>
    <p>{`We asked consumers what the more common use-cases are for when they interacted with either live-agents or chatbots.`}</p>
    <p><img parentName="p" {...{
        "src": "https://i.dashbot.io/wp-home/uploads/2019/04/22231312/consumer-usecases.png",
        "alt": null
      }}></img></p>
    <p>{`The more common use-cases were for general questions (FAQs) and to report a problem. It is similar to the more common use-cases for which enterprises are building.`}</p>
    <h2>{`What do consumers like about chatbots?`}</h2>
    <p>{`We asked consumers how satisfied they are with chatbots across key criteria.`}</p>
    <p><img parentName="p" {...{
        "src": "https://i.dashbot.io/wp-home/uploads/2019/04/22231308/consumer-satisfaction.png",
        "alt": null
      }}></img></p>
    <p>{`While there are areas for improvement, consumers experiences tend to skew positive. In general, about 40-45% of users are either somewhat satisfied or very satisfied with chatbots across the criteria polled, as well as the experience overall – with the most positive experience being around the availability of the chatbot, which agrees nicely with enterprises’ goal of providing 24/7 support.`}</p>
    <p>{`Only about 20-30% were either somewhat unsatisfied, or very unsatisfied with the chatbot experiences – with the ability to understand and ability to resolve issues skewing more negative. These are areas of opportunity for enterprises to develop chatbots that better understand the user’s request, respond appropriately, and lead to higher user satisfaction.`}</p>
    <p>{`When asked specifically what they like about chatbots, consumers cited the 24/7 availability, speed of response, and ease of use as the top likes. These tie in to enterprises top goals of providing 24/7 support and a better user experience.`}</p>
    <p><img parentName="p" {...{
        "src": "https://i.dashbot.io/wp-home/uploads/2019/04/22231309/consumer-likes.png",
        "alt": null
      }}></img></p>
    <h2>{`What do consumers see as areas for improvement?`}</h2>
    <p>{`We asked consumers what they do not like about chatbots as well as areas of improvement.`}</p>
    <p><img parentName="p" {...{
        "src": "https://i.dashbot.io/wp-home/uploads/2019/04/22231311/consumer-dislikes.png",
        "alt": null
      }}></img></p>
    <p>{`Consumers cite the inability of the chatbot to understand correctly and the inability to respond appropriately as the two biggest dislikes. Interestingly enough, the lack of personalization was also an issue. These are all areas of improvement for enterprises to work on with their chatbots.`}</p>
    <p><img parentName="p" {...{
        "src": "https://i.dashbot.io/wp-home/uploads/2019/04/22231313/consumer-areas-of-improvement.png",
        "alt": null
      }}></img></p>
    <p>{`In terms of areas of improvement, consumers would like the ability to escalate to a live agent when needed as well as see the chatbots improve in their ability to understand and in the accuracy of their answers.`}</p>
    <h2>{`Conclusions`}</h2>
    <p>{`While customer service chatbots are still relatively early, more and more enterprises are interested in developing chatbots for customer service. They not only see chatbots as a way to provide 24/7 support at lower costs, but as a way to provide a `}<em parentName="p">{`better`}</em>{`, more consistent, user experience.`}</p>
    <p>{`Consumers are increasingly having more interactions with automated chatbots for customer service. While there are aspects they like, like the availability of the chatbot, there are still plenty of areas for improvement, especially in the chatbots ability to understand and respond satisfactorily.`}</p>
    <p>{`While it can be challenging to build a customer service chatbot that can cover the wide variety of ways a user may interact, understand the user correctly, respond appropriately, and hopefully satisfy the user, there is hope!`}</p>
    <p>{`Dashbot provides a variety of tools and reports to help enterprises better understand how users interact, identify where the natural language processing and responses are breaking down, track escalations and containment, and monitor and improve user satisfaction.`}</p>
    <h2>{`About Dashbot`}</h2>
    <p><a parentName="p" {...{
        "href": "https://www.dashbot.io"
      }}><em parentName="a">{`Dashbot`}</em></a>{` `}<em parentName="p">{`is a conversational analytics platform that enables enterprises and developers to understand user behaviors, optimize response effectiveness, and increase user satisfaction through actionable data and tools.`}</em></p>
    <p><em parentName="p">{`In addition to traditional analytics like engagement and retention, we provide chatbot specific metrics including NLP response effectiveness, sentiment analysis, conversational analytics, and the full chat session transcripts.`}</em></p>
    <p><em parentName="p">{`We also have tools to take action on the data, like our live person take over of chat sessions and broadcast messaging for re-engagement.`}</em></p>
    <p><em parentName="p">{`We support Alexa, Google Home, Facebook Messenger, Slack, Twitter, Kik, SMS, web chat, and any other conversational interface.`}</em></p>
    <p><a parentName="p" {...{
        "href": "https://www.dashbot.io/contact/sales?ref=blog+customer+service+chatbots"
      }}><br parentName="a"></br>
        {`Contact us for a demo `}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      